import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const { publicData, customConfig } = props;
  const { listing, filters } = customConfig || {};

  if (!publicData || !customConfig || !listing?.enumFieldDetails) {
    return null;
  }

  const category = filters[0]?.config?.options?.find(o => o.key === publicData.category);
  const subCategory = category?.subcategories?.find(o => o.key === publicData.subcategory);

  const itemAge = value => {
    if (value === '0') {
      return 'New';
    }

    return value === '1' ? `${value} year old` : `${value} years old`;
  };

  const detailsFilter = [
    {
      key: 'category',
      label: 'ListingPage.detailsCategory',
      value: category?.label,
    },
    { key: 'subcategory', label: 'ListingPage.detailsSubCategory', value: subCategory?.label },
    {
      key: 'measurements',
      label: 'ListingPage.detailsMeasurements',
      value: publicData.measurements,
    },
    {
      key: 'itemAge',
      label: 'ListingPage.detailsItemAge',
      value: itemAge(publicData.itemAge),
    },
    { key: 'brand', label: 'ListingPage.detailsBrand', value: publicData.brand },
    {
      key: 'availability.availableFrom',
      label: 'ListingPage.detailsAvailabilityFrom',
      value: moment(publicData.availability?.availableFrom).format('DD/MM/YYYY'),
    },
    {
      key: 'availability.availableUntil',
      label: 'ListingPage.detailsAvailabilityUntil',
      value: moment(publicData.availability?.availableUntil).format('DD/MM/YYYY'),
    },
  ];

  return (
    <div className={css.sectionDetails}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </h2>
      <ul className={css.details}>
        {detailsFilter.map(detail => {
          if (_.get(publicData, detail.key)) {
            const uppercaseDetailValue =
              detail.value.charAt(0).toUpperCase() + detail.value.slice(1);

            return (
              <li key={detail.key} className={css.detailsRow}>
                <span className={css.detailLabel}>
                  <FormattedMessage id={detail.label} />
                </span>
                <span>{uppercaseDetailValue}</span>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default SectionDetailsMaybe;
