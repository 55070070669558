import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { UserCard, Modal } from '../../components';
import EnquiryForm from './EnquiryForm/EnquiryForm';

import css from './ListingPage.module.css';

const SectionAuthorMaybe = props => {
  const { listing, currentUser } = props;

  if (!listing.author) {
    return null;
  }

  return (
    <div id="author" className={css.sectionAuthor}>
      <h2 className={css.aboutProviderTitle}>
        <FormattedMessage id="ListingPage.aboutProviderTitle" />
      </h2>
      <UserCard user={listing.author} currentUser={currentUser} />
    </div>
  );
};

export default SectionAuthorMaybe;
